import React from 'react'
import { useRouter } from 'next/router'
import { WContainer } from '@components/atoms'
import { SimpleCheck } from 'components/atoms/Icons'
import { FormLogin, Page } from 'components/organisms'
import { MyAccountSection } from 'components/molecules'
import styles from './LoginTemplate.module.sass'
import { useTrans } from '@hooks/index'

const LoginTemplate = ({ pagina, from }) => {
  const router = useRouter()
  const t = useTrans()

  const nextPage = router.query?.next
  const fromDelete = router.query?.delete

  const email = router.query?.email
  const email_exists = email
    ? 'Questa email risulta essere già registrata. Accedi con le tue credenziali.'
    : pagina.descrizione

  return (
    <Page pagina={pagina} item={pagina}>
      <WContainer variant="xxs">
        {fromDelete && (
          <div className={styles.confirmDelete}>
            <span className={styles.icon}>
              <SimpleCheck />
            </span>
            <span>{t('Il tuo profilo è stato eliminato')}</span>
          </div>
        )}

        <MyAccountSection>
          {(!!pagina.titolo || !!pagina.descrizione) && (
            <div className={styles.head}>
              {!!pagina.titolo && <h1 className={styles.title}>{pagina.titolo}</h1>}
              {!!email_exists && (
                <div
                  className={styles.description}
                  dangerouslySetInnerHTML={{ __html: email_exists }}
                />
              )}
            </div>
          )}
          <FormLogin nextPage={nextPage} from={from} email={email} />
        </MyAccountSection>
      </WContainer>
    </Page>
  )
}

export default LoginTemplate
