import React from 'react'
import { initializeApollo } from 'gql/client'
import { PAGINA } from 'utils/queries'
import LoginTemplate from '@components/templates/LoginTemplate/LoginTemplate'
import { NextWrapper } from 'data-fetching-wrapper'

const LoginPage = ({ pagina }) => <LoginTemplate pagina={pagina} />

export const getStaticProps = NextWrapper.getStaticProps(async () => {
  const client = initializeApollo()

  const {
    data: { pagina },
  } = await client.query({
    query: PAGINA,
    variables: { chiave: 'login' },
  })

  if (!pagina) {
    return {
      notFound: true,
    }
  }

  return {
    props: { pagina },
    revalidate: 60 * 5,
  }
}, '/login')

export default LoginPage
